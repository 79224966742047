import React, { Fragment} from 'react';

import gracias1 from '../Images/Iconos/facebook.png';
import gracias2 from '../Images/Iconos/instagram.png';
import gracias3 from '../Images/completado.png';


const Gracias = props => {

    return (

        <Fragment>

                <div className="container center" style={{padding:"50px 0px"}}>

                    <img className='imagen_superior_agradecimiento' src={gracias3} alt=""></img>

                    <p className="titulo_3" style={{color:"#793E90"}}>
                        Envío exitoso
                    </p>
                    <p className='texto-gracias'>
                        Gracias por completar el formulario con tus datos.
                    </p>
                    <p className='texto-gracias juntar_texto'>
                        En breve, nos pondremos en contacto contigo.
                    </p>

                    <div className='imagenes-gracias-padre'>
                        <a target='_blanck' href="https://www.facebook.com/drNorbertoJBroon">
                            <div className='imagenes-gracias'>
                                <img style={{width:"100%"}} src={gracias1} alt=""></img>
                            </div>
                        </a>
                        <a target='_blanck' href="https://www.instagram.com/drnorbertobroon/" style={{margin:"0px 3%"}}>
                            <div className='imagenes-gracias'>
                                <img style={{width:"100%"}} src={gracias2} alt=""></img>
                            </div>
                        </a>
                       
                    </div>

                </div>
                
        </Fragment>
    )
}

export default Gracias;