import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';
import Header from '../../../Components/SideNav';


class Contactos extends Component {
    
    constructor(props){
        super(props)
        this.state = {}
    }


    archivar = data => {
        console.log("archivar");
        const { firestore } = this.props;
        let editar = {...data};
        editar.archivado = true;
        console.log(editar);
        firebaseHelper.archivar(data.id, editar, 'contacto', firestore);
    }


    render(){
        if (!this.props.contacto) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="CONTACTOS" />

                    <div className="container">
                        {this.props.contacto.length === 0 ? (
                            <div className="center">
                                <h2 id="font">No hay mensajes</h2>
                            </div>
                        ) : (
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Telefono</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody id="font">
                                    {this.props.contacto.map(dato => (
                                        <tr key={dato.id}>
                                            <td>{dato.nombre}</td>
                                            <td>{dato.telefono}</td>
                                            <td>
                                                {dato.estado ? (
                                                    <div style={{
                                                        width:"25px",
                                                        height:"25px",
                                                        background:"green"
                                                    }} />
                                                ) : (
                                                    <div style={{
                                                        width:"25px",
                                                        height:"25px",
                                                        background:"red"
                                                    }} />
                                                )}
                                            </td>
                                            <td>
                                                <Link style={{marginLeft:'14%'}} to={`/admin/contacto/${dato.id}`}><i class="material-icons" style={{color:'#1bc9b6'}}>remove_red_eye</i></Link>
                                                <a href="#!" onClick={ () => this.archivar(dato)}  style={{marginLeft:'14%'}}><i class="material-icons" style={{color:'#1bc9b6'}}>archive</i></a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                    
                </Fragment>
            );
        }
    }
}

Contactos.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'contacto',
            where: [
                "archivado", "==", false
            ]
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        contacto: ordered.contacto
    }))
)(Contactos);