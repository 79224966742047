import React, {Fragment}  from 'react';

import Formulario from '../Components/Formulario';

import icon1 from '../Images/Iconos/Servicios/1.svg'

import img1 from '../Images/Escritorio/Servicios/2.png'
import img2 from '../Images/Escritorio/Servicios/3.png'
import img3 from '../Images/Escritorio/Servicios/4.png'
import img4 from '../Images/Escritorio/Servicios/5.png'
import img5 from '../Images/Escritorio/Servicios/6.png'
import img6 from '../Images/Escritorio/Servicios/7.png'


const Servicios = () => { 
    return(
        <Fragment>
            <div>
                <div className='img_servicios'>
                    <p className='titulo_1'>
                        Especialidades
                    </p>
                </div>
                <div className='center cont_6 cont_2 texto_4'>
                    <p>
                        La odontología es una ciencia de la salud encargada de la prevención, diagnóstico, tratamiento 
                        y pronóstico del aparato estomatognático, incluye los maxilares, la articulación temporomandibular, 
                        los dientes, el tejido periodontal (encía, hueso), la lengua, además de los aspectos funcionales, 
                        estéticos que permiten a una persona su desarrollo psicoemocional y social.
                    </p>
                    <p>
                        En <span style={{color:"#7A3C8F"}}>Odontólogos Especialistas Militares</span> te ofrecemos servicios de calidad <br/>
                        que son realizados con microscopio dental. 
                    </p>
                </div>

                <div>
                    <p className='center titulo_2 cont_8' style={{color:"#000"}}>
                        Conoce nuestra amplia gama de servicios
                    </p>
                        <div className='flex_padre' style={{flexWrap:"wrap"}}>
                            <div className='flex_padre_4 texto_5'>
                                <div className='box_4'>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Limpieza dental</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Diseño de sonrisa</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Resinas</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Blanqueamiento dental</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Implantes dentales y periodoncia</p>
                                    </div>
                                </div>
                                <div className='box_4'>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Prótesis dental</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Carillas dentales e incrustaciones cerámicas</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Bichectomia</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Cirugía de 3er molar</p>
                                    </div>
                                    <div className='flex_padre_3' style={{alignItems:"center"}}>
                                        <img src={icon1} alt="" style={{width:"25px", height:"18px", marginRight:"20px"}}/>
                                        <p>Cirugía de quistes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div className='cont_3'>
                    <p className='titulo_5 cont_8 center'>
                        Nuestras especialidades
                    </p>
                    <div className='flex_padre' style={{flexWrap:"wrap"}}>
                        <div className='box_5'>
                            <div className='caja_1'>
                                <img src={img1} alt="" className='img_servicios_2' />
                            </div>
                            <div className='box_5_1'>
                                <h1>Cirugía bucal, endodóntica, periodontal y maxilofacial</h1>
                                <p>
                                    Especialidades que atienden intervenciones <br/>
                                    quirúrgicas bucales, como la...
                                </p>
                            </div>
                            <div className='flex_padre'>
                                <a href="/especialidades/cirugia-bucal-endodontica-periodontal-y-maxilofacial" className='btn_2'>
                                    Leer más
                                </a>
                            </div>
                        </div>
                        <div className='box_5'>
                            <div className='caja_1'>
                                <img src={img2} alt="" className='img_servicios_2' />   
                            </div>
                            <div className='box_5_1'>
                                <div style={{padding:"0px 10px"}}>
                                    <h1> Blanqueamiento dental</h1>
                                    <p> Es un procedimiento para aclarar los dientes <br/> con fines estéticos, se utilizan productos químicos…</p>
                                </div>
                            </div>
                            <div className='flex_padre'>
                                <a href="/especialidades/blanqueamiento-dental" className='btn_2'>
                                    Leer más
                                </a>
                            </div>
                        </div>
                        <div className='box_5'>
                            <div className='caja_1'>
                                <img src={img3} alt="" className='img_servicios_2' />
                            </div>
                            <div className='box_5_1' style={{padding:"0px 10px"}}>
                                <h1>Endodoncia con <br/> Microscopio Dental</h1>
                                <p>
                                    La Endodoncia es un procedimiento que se realiza para
                                    conservar al diente natural en la boca, consiste en...
                                </p>
                            </div>
                            <div className='flex_padre'>
                                <a href="/especialidades/endodoncia-con-microscopio-dental" className='btn_2'>
                                    Leer más
                                </a>
                            </div>
                        </div>
                        <div className='box_5'>
                            <div className='caja_1'>
                                <img src={img4} alt="" className='img_servicios_2' />
                            </div>
                            <div className='box_5_1'>
                                <div style={{padding:"0px 10px"}}>
                                    <h1>Dolor, infección dental y enfermedad de encías</h1>
                                    <p>
                                        Son padecimientos bucales que pueden ser leves como caries 
                                        próxima al nervio que causa dolor dental, hasta graves como...
                                    </p>
                                </div>
                            </div>
                            <div className='flex_padre'>
                                <a href="/especialidades/dolor-infeccion-dental-y-enfermedad-de-encias" className='btn_2'>
                                    Leer más
                                </a>
                            </div>
                        </div>
                        <div className='box_5'>
                            <div className='caja_1'>
                                <img src={img5} alt="" className='img_servicios_2' />
                            </div>
                            <div className='box_5_1'>
                                <h1> Traumatismos dentales</h1>
                                <p>Es una lesión en uno o más dientes como consecuencia del impacto físico recibido…</p>
                            </div>
                            <div className='flex_padre'>
                                <a href="/especialidades/traumatismos-dentales" className='btn_2'>
                                    Leer más
                                </a>
                            </div>
                        </div>
                        <div className='box_5'>
                            <div className='caja_1'>
                                <img src={img6} alt="" className='img_servicios_2' />
                            </div>
                            <div className='box_5_1'>
                                <h1>Protesis e <br/> implantes dentales</h1>
                                <p style={{padding:"0px 5%"}}>Especialidad responsable de devolver la función y estética a personas que han ….</p>
                            </div>
                            <div className='flex_padre'>
                                <a href="/especialidades/protesis-e-implantes-dentales" className='btn_2'>
                                    Leer más
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Formulario />
            </div>

        </Fragment>
    )
}

export default Servicios;