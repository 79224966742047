import React, { Fragment } from "react";

import img2 from "../Images/Escritorio/Nosotros/dr1.jpeg";
import img3 from "../Images/Escritorio/Nosotros/dr2.jpeg";
import img4 from "../Images/Escritorio/Nosotros/dr3.jpeg";

import icon1 from "../Images/Iconos/Nosotros/1.svg";
import icon2 from "../Images/Iconos/Nosotros/2.svg";
import icon3 from "../Images/Iconos/Nosotros/3.svg";

import icon4 from "../Images/Iconos/Nosotros/4.svg";
import icon5 from "../Images/Iconos/Nosotros/5.svg";
import icon6 from "../Images/Iconos/Nosotros/6.svg";
import icon7 from "../Images/Iconos/Nosotros/7.svg";
import icon8 from "../Images/Iconos/Nosotros/8.svg";

const Nosotros = () => {
  return (
    <Fragment>
      <div>
        <div className="img_nosotros">
          <p className="titulo_1">Nosotros</p>
        </div>
        <div className="center texto_4 cont_2 cont_7">
          <p>
            Somos un grupo de cirujanos dentistas profesionales con amplia
            experiencia <br />
            en diversas especialidades de la odontología.
          </p>
          <p>Ofrecemos un tratamiento 100% personalizado.</p>
        </div>
        <div
          className="flex_padre cont_2"
          style={{ flexWrap: "wrap", alignItems: "stretch" }}
        >
          <div className="box_1">
            <img src={icon2} alt="" className="icon_nosotros_1" />
            <p className="titulo_3">Equipo</p>
            <p className="texto_4">
              Contamos con tecnología vanguardista <br />
              y conocimientos actualizados que caracterizan <br />a nuestros
              expertos odontólogos.
            </p>
          </div>
          <div className="box_1">
            <img src={icon3} alt="" className="icon_nosotros_1" />
            <p className="titulo_3" style={{ color: "#7A3C8F" }}>
              Visión
            </p>
            <p className="texto_4">
              Ser una clínica multidisciplinaria especializada <br />
              en la atención de enfermedades bucodentales <br />
              para mejorar la salud de nuestros pacientes <br />y su entorno
              social, devolviéndole la funcionalidad, estética y autoestima.
            </p>
          </div>
          <div className="box_1">
            <img src={icon1} alt="" className="icon_nosotros_1" />
            <p className="titulo_3">Misión</p>
            <p className="texto_4">
              Proporcionar servicios odontológicos especializados con calidad,
              calidez, ética y profesionalismo, enfocados a la prevención de
              enfermedades bucodentales que impactan en la salud de nuestros
              pacientes.
            </p>
          </div>
        </div>

        <div className="cont_3" id="norberto" style={{ marginBottom: "40px" }}>
          <div
            className="flex_padre"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <div className="flex_1" style={{ padding: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={img2} alt="" className="img_nosotros_2" />
              </div>
            </div>
            <div className="flex_1 caja_3">
              <p className="titulo_2" style={{ marginBottom: "0px" }}>
                Cirujano Dentista Militar Norberto Broon
              </p>
              <p className="texto_4" style={{ marginTop: "0px" }}>
                Especialista en endodoncia y cirugía con microscopio dental
              </p>
              <p className="texto_5">
                Cédula profesional: <br />
                CP - 2010152 y CPE - AEIE13907
              </p>
              <div className="texto_5" style={{ color: "#484848" }}>
                <p>
                  Soy experto en control del dolor e infección dental, cirugía
                  de trasplante, reimplante, traumatismos y blanqueamiento. Para
                  la atención de mis pacientes trabajo con{" "}
                  <b>MICROSCOPIO DENTAL</b> para una mayor precisión en el
                  diagnóstico y ofrecer un tratamiento menos invasivo y más
                  efectivo.
                </p>
                <p>
                  Egresado de la{" "}
                  <b>
                    Escuela Militar de Odontologia - Universidad del Ejército y
                    Fuerza Aérea.
                  </b>
                </p>
                <p>
                  Especialista en endodoncia, egresado de la{" "}
                  <b>
                    Escuela Militar de Graduados de Sanidad - Universidad del
                    Ejército y Fuerza Aérea.
                  </b>
                </p>
                <p>
                  Maestria en Odontologia por la{" "}
                  <b>
                    Facultad de Odontologia de Bauru - Universidad de Sao Paulo,
                    Brasil.
                  </b>
                </p>
                <p>
                  Doctorado en{" "}
                  <b>
                    Ciencias por el Centro Universitario de Ciencias de la Salud
                    - Universidad de Guadalajara.
                  </b>
                </p>
                <p>
                  Certificado por el{" "}
                  <b>Colegio Nacional de Endodoncistas Militares, A.C.</b>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="cont_4" id="diana" style={{ marginBottom: "40px" }}>
          <div
            className="flex_padre"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <div className="flex_1" style={{ padding: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={img3} alt="" className="img_nosotros_2" />
              </div>
            </div>
            <div className="flex_1 caja_3">
              <p className="titulo_2" style={{ marginBottom: "0px" }}>
                Cirujano dentista Diana Figueroa
              </p>
              <p className="texto_4" style={{ marginTop: "0px" }}>
                Especialista en prótesis y estética dental
              </p>
              <p className="texto_5">
                Cédula profesional: <br />
                CP - 12417934 Y CPE - 12990952
              </p>
              <div className="texto_5 box_2" style={{ color: "#484848" }}>
                <p>
                  Mi Especialidad se refiere a regresarles a los pacientes su
                  sonrisa, teniendo siempre en cuenta los ámbitos orales
                  biológicos y funcionales, mi propósito es mantener en boca los
                  dientes el mayor tiempo mediante una prótesis dental, soy
                  experta en prótesis fija, removible, total, implantes dentales
                  y desde luego la odontología estética con carillas, diseño de
                  sonrisa para devolver a las personas funcionalidad, estética y
                  su autoestima
                </p>
                <p>
                  Cirujano Dentista egresada de la Universidad de Guadalajara
                </p>
                <p>
                  Prestador de servicio social en el Hospital Militar Regional
                  de Guadalajara
                </p>
                <p>
                  Especialista en Prostodoncia por la Universidad Autónoma de
                  Guadalajara
                </p>
                <p>7 Años de experiencia en el ámbito laboral</p>
                <p>
                  Actualización continua en congresos nacionales e
                  internacionales
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="cont_4" id="alvaro" style={{ marginBottom: "60px" }}>
          <div
            className="flex_padre"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <div className="flex_1" style={{ padding: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={img4} alt="" className="img_nosotros_2" />
              </div>
            </div>
            <div className="flex_1 caja_3">
              <p className="titulo_2" style={{ marginBottom: "0px" }}>
                Cirujano dentista Álvaro Villegas
              </p>
              <p className="texto_4" style={{ marginTop: "0px" }}>
                Especialista en periodoncia e implantes
              </p>
              <p className="texto_5">
                Cédula profesional: <br />
                CP - 11719608 Y CPE - 12981756
              </p>
              <div className="texto_5 box_2" style={{ color: "#484848" }}>
                <p>
                  Mi compromiso con los pacientes es devolver la salud de sus
                  encías, ya que son los cimientos para tener una boca sana y
                  funcional. Así como ayudar en el reemplazo de dientes por
                  medio de implantes dentales o bien ayudar a regresar la
                  funcionalidad de dientes dañados
                </p>
                <p>Universidad Autónoma de Guadalajara</p>
                <p>
                  Egresado de cirujano dentista por la Universidad Cuauhtémoc de
                  Guadalajara.
                </p>
                <p>Segundo lugar de generación en licenciatura.</p>
                <p>
                  Graduado de la especialidad de periodoncia e implantología por
                  la Universidad Autónoma de Guadalajara
                </p>
                <p>
                  Experiencia laboral por 2 años en el área de cirugía
                  maxilofacial
                </p>
                <p>Diplomado de exodoncia complicada.</p>
                <p>7 años de experiencia en práctica profesional.</p>
                <p>
                  Actualización continua en congresos nacionales e
                  internacionales.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cont_5 flex_padre">
          <a href="/contacto" className="btn_1">
            <p>Agenda una cita</p>
          </a>
        </div>

        <div className="fondo_2">
          <p className="center titulo_5">Valores</p>
          <div
            className="flex_padre_2"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <div className="box_3">
              <img src={icon8} alt="" className="icon_nosotros_2" />
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>Honestidad</p>
            </div>
            <div className="box_3">
              <img src={icon5} alt="" className="icon_nosotros_2" />
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                Responsabilidad
              </p>
            </div>
            <div className="box_3">
              <img src={icon6} alt="" className="icon_nosotros_2" />
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                Profesionalismo
              </p>
            </div>
            <div className="box_3">
              <img src={icon7} alt="" className="icon_nosotros_2" />
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>Respeto</p>
            </div>
            <div className="box_3">
              <img src={icon4} alt="" className="icon_nosotros_2" />
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>Calidad</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Nosotros;
