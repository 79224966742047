import React, {Fragment, useState} from 'react';
import { firestoreConnect } from 'react-redux-firebase';

import firebaseHelper from '../Helpers/Firebase';

import icon1 from '../Images/Footer/4.svg'
import icon2 from '../Images/Footer/5.svg'
import icon3 from '../Images/Footer/6.svg'
import icon4 from '../Images/Footer/8.svg'

const Formulario = props => {

    const [contacto, setContacto] = useState({
        nombre:"",
        telefono:"",
        correo:"",
        mensaje:"",
        estado: false,
        archivado: false
    });

    const enviarComentario = e => {
        const { firestore } = props;
        e.preventDefault();
        console.log("enviar");
        console.log({contacto});
        firebaseHelper.agregar_2("contacto", firestore, contacto)
    }

    const leerDato = e => {
        e.preventDefault();
        setContacto({...contacto, [e.target.name]: e.target.value});
    }

    return(
        <Fragment>
            <div className='flex_formulario'>
                <div className='flex_formulario_2'>
                    <p className='titulo_formulario'>
                        ¡Agenda <br/>
                        <span>una cita!</span>
                    </p>
                    <div className='texto_formulario'>
                        <p>
                            Déjanos tus datos y en breve <br/>
                            nos pondremos en contacto contigo.
                        </p>
                        <p>
                            O mándanos un mensaje directamente <br/>
                            a nuestras redes sociales.
                        </p>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-evenly", alignItems:"center", padding:"0px 15%"}}>
                        <div>
                            <a target='blanck' href="https://www.instagram.com/drnorbertobroon">
                                <img src={icon3} alt="" className='icon_formulario'/>
                            </a>
                        </div>
                        <div>
                            <a target='blanck' href="https://www.facebook.com/drNorbertoJBroon">
                                <img src={icon1} alt="" className='icon_formulario' />
                            </a>
                        </div>
                        <div>
                            <a target='blanck' href="https://api.whatsapp.com/send?phone=3318941320&text=Hola,%20muy%20buenas%20tardes,%20me%20gustar%C3%ADa%20recibir%20mas%20informaci%C3%B3n%20o%20agendar%20una%20cita">
                                <img src={icon2} alt="" className='icon_formulario'/>
                            </a>
                        </div>
                    </div>
                    <div className='texto_formulario' >
                        Visita la plataforma de <a target='blanck' href='https://www.doctoralia.com.mx/norberto-broon/dentista-odontologo/zapopan' style={{color:"#1BC9B6"}}><i>Doctoralia</i></a><br/>
                        y <b>¡agenda tu cita ya mismo!</b>
                        <div className='flex_padre' style={{marginTop:"10px"}}>
                            <a target='blanck' href="https://www.doctoralia.com.mx/norberto-broon/dentista-odontologo/zapopan">
                                <img src={icon4} alt="" className='icon_formulario' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='flex_formulario_3'>
                    <form onSubmit={enviarComentario}>
                        <input 
                            placeholder="Nombre" 
                            id="nombre" 
                            name="nombre" 
                            type="text" 
                            class="validate require"
                            onChange={leerDato}
                        />
                        <input 
                            placeholder="Teléfono" 
                            id="telefono" 
                            type="tel" 
                            name="telefono" 
                            class="validate require"
                            onChange={leerDato}
                        />
                        <input 
                            placeholder="Correo" 
                            id="correo" 
                            name="correo" 
                            type="email" 
                            class="validate require"
                            onChange={leerDato}
                        />
                        <textarea 
                            placeholder='Motivo de consulta' 
                            name="mensaje"  
                            id="mensaje" 
                            class="materialize-textarea"
                            onChange={leerDato}
                        />

                        <div style={{display:"flex", justifyContent:"center"}}>
                            <button type="submit" className='btn_formulario'>
                                <p>
                                    Enviar
                                </p>
                            </button>
                        </div>
                    </form>
                </div>
                
            </div>

        </Fragment>
    )


}

export default firestoreConnect() (Formulario);