import React, {Fragment, useEffect}  from 'react';
import M from 'materialize-css';

import img1 from '../../Images/Escritorio/Servicios/Traumatismo/1.png';
import img2 from '../../Images/Escritorio/Servicios/Traumatismo/2.png';
import img3 from '../../Images/Escritorio/Servicios/Traumatismo/3.jpg';

const Traumatismo = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    },[]);
    
    return(
        <Fragment>
            <div>
                <div className='cont_2 cont_6 center'>
                    <p className='titulo_2' style={{color:"#000", padding:"0px 15%"}}>
                        Traumatismos <span style={{color:"#1BC9B6"}}>dentales</span> 
                    </p>
                    <div className='texto_4'>
                        <p>
                            Lesiones en uno o más dientes y que impactan sobre los tejidos de sostén, tanto duros como 
                            blandos (hueso y encía). Los traumatismos dentales leves van desde un simple golpe frontal en los 
                            dientes que puede provocar el oscurecimiento dental, moderado como la fractura de raíz, la 
                            subluxación (movilidad leve) y el trauma grave como la intrusión (introducción del diente al hueso) y 
                            avulsión (salida del diente).
                        </p>
                    </div>
                </div>
                <div className='cont_2 '>
                    <p className='center titulo_6'>
                        CASOS CLINICOS
                    </p>
                </div>
                <div className='flex_padre cont_9' style={{flexWrap:"wrap"}}>
                    <div className='box_6'>
                        <img src={img1} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 9%"}}>
                                Trauma dental por caída de juego mecánico

                            </h1>
                            <p>
                                Paciente femenino de 11 años de edad con diagnóstico 
                                de avulsión (salida) del incisivo central superior y 
                                fractura de tabla ósea. El tratamiento fue endodoncia 
                                y reimplante dental, 5 años después con buena 
                                estabilidad pero con reabsorción radicular.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img2} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 11%"}}>
                                Trauma dental por accidente automovilístico
                            </h1>
                            <p>
                                Paciente masculino de 23 años de edad con avulsión 
                                del diente frontal que lo mantuvo 13 días dentro de 
                                una botella de agua, se recoloco su diente (reimplante 
                                dental) y un año después se aprecia estable y con una 
                                leve retracción de encía.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img3} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 11%"}}>
                                Trauma en dientes frontales por caída
                            </h1>
                            <p>
                                Paciente masculino de 15 de edad, que tuvo una caída 
                                y se fracturó la mitad de sus dos dientes centrales 
                                superiores, se le colocaron dos resinas estéticas 
                                para devolver la funcionalidad, estética y sonrisa.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Traumatismo;