import React from 'react';


const AvisoPrivacidad = () => {
    return (
        <div className='container' style={{marginBottom:"50px"}}>
            <p className='titulo_9 center' style={{marginBottom:"0px"}}>
                AVISO DE PRIVACIDAD <br />
                ODONTÓLOGOS ESPECIALISTAS MILITARES
            </p>
            <p>
                Con fundamento en los artículos 1°, 2°, 8°, 9°, 12°, 16° y demás relativos 
                aplicables de la Ley Federal de Protección de Datos Personales en Posesión 
                de los Particulares y su reglamento, a continuación, hacemos de su 
                conocimiento el siguiente
            </p>
            <p>
                El que suscribe Dr. NORBERTO JUAREZ BROON con domicilio en Avenida Cuauhtémoc 
                1192, colonia ciudad del sol, Zapopan, Jalisco. CP 45050.
            </p>
            <p>
                En mi carácter de odontólogo tratante y responsable de la información, hago 
                de su conocimiento que me comprometo a que los datos personales proporcionados 
                al de la voz serán tratados bajo medidas de seguridad adecuadas, siempre 
                garantizando su confidencialidad. Dichos datos tienen como finalidad principal 
                el integrar debidamente el expediente clínico que le corresponde con fundamento 
                en lo que dispone la Norma Oficial Mexicana 004-SSA3-2012, ya que dicho documento 
                constituye un conjunto único de información y datos personales de un paciente, 
                que se integra dentro de todo tipo de establecimiento para la atención médica, 
                ya sea público, social o privado, el cual, consta de documentos escritos, gráficos, 
                imagenológicos, electrónicos, magnéticos, electromagnéticos, ópticos, magneto-ópticos 
                y de cualquier otra índole, en los cuales, el personal de salud deberá hacer los 
                registros, anotaciones, en su caso, constancias y certificaciones correspondientes 
                a su intervención en la atención médico-odontológica del paciente, con apego a las 
                disposiciones jurídicas aplicables. Recabamos sus datos personales de forma directa 
                cuando usted mismo nos los proporciona por diversos medios (incluidos los electrónicos), 
                como cuando nos da información con motivo de una consulta médica. Otros motivos por 
                los que serán utilizados sus datos personales son los que se enuncian a continuación:
            </p>
            <p>
                1. Prestación de servicios odontológicos, incluyendo entre otros: i) consultas, ii) 
                estudios diagnósticos, y iii) demás fines relacionados con servicios de salud bucodental. 
                2. Creación, estudio, análisis, actualización y conservación del expediente clínico. 3. 
                Facturación y cobranza por servicios. 4. Estudios, registros, estadísticas y análisis de 
                información de salud. 5. Conservación de registros para seguimiento a servicios, prestación 
                de servicios en el futuro y en general para dar seguimiento a cualquier relación contractual. 
                6. Análisis estadísticos y de mercado. 7. Comunicación de información relevante y servicios. 
                Le informamos que algunos de los datos personales recabados en los términos del presente 
                aviso, tienen el carácter de sensibles y por lo tanto, nos comprometemos a que los mismos 
                serán tratados de igual manera bajo las medidas de seguridad correspondientes, siempre 
                garantizando su confidencialidad, por lo tanto es necesario que manifieste que en este 
                acto otorga consentimiento y autorización para que sus datos personales en general (incluyendo 
                los sensibles) sean tratados conforme a los términos y condiciones del presente aviso de 
                privacidad. En todo momento usted podrá revocar el consentimiento que nos ha otorgado para 
                el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. 
                Usted tiene el derecho de acceder a los datos personales que poseemos y a los detalles del 
                tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos, instruirnos 
                o cancelarlos cuando considere que resulten ser excesivos o innecesarios para las 
                finalidades que justificaron su obtención u oponerse al tratamiento de los mismos para 
                fines específicos. Sus datos personales pueden ser transferidos y tratados por personas 
                distintas al que suscribe, en ese sentido, su información puede ser compartida con otros 
                profesionales de la salud y /o médicos especialistas que en su momento sean requeridos como 
                Interconsultantes para brindarle una atención médica oportuna y de calidad. Si usted no 
                manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que 
                ha otorgado su consentimiento para ello. Manifiesto que entiendo todo lo anteriormente 
                explicado y firmo al calce para los efectos legales a los que haya lugar, sin que exista o 
                medie coacción física y/o psicológica alguna.
            </p>
        </div>
    )
}

export default AvisoPrivacidad;