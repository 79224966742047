import React, {Fragment, useEffect}  from 'react';
import M from 'materialize-css';

import img1 from '../../Images/Escritorio/Servicios/Protesis/1.jpg';
import img2 from '../../Images/Escritorio/Servicios/Protesis/2.jpg';
import img3 from '../../Images/Escritorio/Servicios/Protesis/3.jpg';

const Protesis = () =>{
    
    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    },[]);

    return(
        <Fragment>
            <div>
                <div className='cont_2 cont_6 center'>
                    <p className='titulo_2' style={{color:"#000", padding:"0px 10%"}}>
                        Protesis e <span style={{color:"#1BC9B6"}}>Implantes Dentales</span>
                    </p>
                    <div className='texto_4'>
                        <p>
                            Especialidad responsable de devolver la función y estética a personas que han perdido dientes, para 
                            esto utilizamos Prótesis Fija, Removible, Total y Prótesis sobre implantes. 
                        </p>
                        <p>
                            Cualquier tipo de prótesis son 
                            precisas y seguras, permiten una mejor estabilidad en los espacios donde faltan dientes y 
                            armonizan las estructuras dentales y faciales, así como la estética y función de una persona.
                        </p>
                    </div>
                </div>
                <div className='cont_2 '>
                    <p className='center titulo_6'>
                        CASOS CLINICOS
                    </p>
                </div>
                <div className='flex_padre cont_9' style={{ flexWrap:"wrap"}}>
                    <div className='box_6'>
                        <img src={img1} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 15%"}}>
                                Rehabilitación integral dental 
                            </h1>
                            <p>
                                Paciente femenino de 56 años de edad, a quien se le 
                                cambiaron sus restauraciones a base de metal con 
                                acrílico no-funcionales, antiestéticas, desajustadas, 
                                con perdida de la línea de sonrisa, la paciente refería 
                                no sentirse a gusto. Fue rehabilitada de manera integral 
                                con prótesis fija de zirconia y removible inferior; en 
                                la actualidad se encuentra en armonía, estable, estético 
                                y funcional.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img2} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 15%"}}>
                                Rehabilitación con prótesis fija dental  
                            </h1>
                            <p>
                                Paciente masculino de 35 años de edad, que presentaba 
                                sus dientes frontales, uno con caries y el otro con 
                                corona de porcelana desajustada, antiestética y sin 
                                funcionalidad. Fue rehabilitado con 2 coronas cerámicas 
                                en ambos dientes frontales, se aprecian armónicas, 
                                estéticas y funcionales, esta muy contento con el 
                                resultado obtenido.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img3} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 15%"}}>
                                Rehabilitación con implantes  
                            </h1>
                            <p>
                                Paciente femenino de 42 años de edad rehabilitada con implante 
                                de titanio y corona cerámica en un primer molar superior, se 
                                observa buen nivel de hueso, armonía, estabilidad, estética y 
                                funcionalidad, los implantes son la menor opción para dientes 
                                perdidos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Protesis;