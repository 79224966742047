import React, {Fragment}  from 'react';


const Error = () => {

    return(
        <Fragment>
            <div className='fondo_1'>
                <div className='flex_error'>
                    <div className='flex_2'>
                        <div className='img_error'/>
                    </div>
                    <div className='center flex_2'>
                        <p className='titulo_3'>
                            Error
                        </p>
                        <p className='titulo_4'>
                            404
                        </p>
                        <p className='texto_3'>
                            Página no encontrada
                        </p>
                        <p className='texto_2'>
                            La página a la cuál intentas acceder no está disponible temporalmente.
                        </p>
                        <div className='flex_padre'>
                            <a href="/" className='btn_1'>
                                <p>
                                    Regresa al inicio
                                </p>
                            </a>
                        </div>
                    </div>
                </div>                
            </div>
        </Fragment>
    )
}

export default Error;