import React, {Fragment, Component} from 'react';
import M from 'materialize-css';

import img1 from '../Images/Header/logo1.png';

import icon1 from '../Images/Header/1.svg';
import icon2 from '../Images/Header/2.svg';
import icon3 from '../Images/Header/3.svg';
import icon4 from '../Images/Header/4.svg';
import icon5 from '../Images/Header/5.svg';
import icon6 from '../Images/Header/6.svg';

class Header extends Component {
    
    componentDidMount = () => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }
    
    render (){
        return [
            <Fragment> 
                {window.screen.width <= 850 ? (
                        <div>
                            
                        </div>             
                    ) : (
                        <div className='flex_padre_header'>
                            <div className='flex_header_1'>
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <img src={icon1} alt="" style={{width:"30px", height:"30px", margin:"0px 15px"}} />
                                    <p className='texto_header'>
                                        33 1894 1320 <br/>
                                        33 2335 5830
                                    </p>
                                </div>
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <img src={icon2} alt="" style={{width:"30px", height:"30px", margin:"0px 15px"}} />
                                    <p className='texto_header'>
                                        Lunes a viernes / 8:30 am. - 9 pm. <br/>
                                        Sábados / 8:30 am - 3 pm
                                    </p>
                                </div>
                            </div>
                            <div className='flex_header_2'>
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <a target='blanck' href="https://www.doctoralia.com.mx/norberto-broon/dentista-odontologo/zapopan">
                                        <img src={icon6} alt="" style={{width:"25px", height:"24px", margin:"0px 10px"}}/>
                                    </a>
                                    <a target='blanck' href="https://www.instagram.com/drnorbertobroon">
                                        <img src={icon4} alt="" style={{width:"25px", height:"24px", margin:"0px 10px"}}/>
                                    </a>
                                    <a target='blanck' href="https://www.facebook.com/drNorbertoJBroon">
                                        <img src={icon3} alt="" style={{width:"25px", height:"24px", margin:"0px 10px"}}/>
                                    </a>
                                    <a target='blanck' href="https://api.whatsapp.com/send?phone=3318941320&text=Hola,%20muy%20buenas%20tardes,%20me%20gustar%C3%ADa%20recibir%20mas%20informaci%C3%B3n%20o%20agendar%20una%20cita">
                                        <img src={icon5} alt="" style={{width:"25px", height:"24px", margin:"0px 10px"}}/>
                                    </a>
                                </div>
                                <div style={{backgroundColor:"#7A3C8F", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", width:"45%"}}>
                                    <a href="/contacto" className='texto_header_2'>
                                        <p>
                                            HAZ UNA CITA
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}       
                <nav className='header' style={{boxShadow:"none"}}>
                    <div class="nav-wrapper" style={{backgroundColor:"#fff"}}>    
                        <a href="/" class="brand-logo">
                            <img src={img1} alt="" className='img_header'/>    
                        </a>
                        <div>
                            <a href="#!" data-target="mobile-demo" class="sidenav-trigger">
                                <i style={{color:"#000000"}} class="material-icons">
                                    menu
                                </i>
                            </a>
                        </div>
                        <div>
                            <ul class="right hide-on-med-and-down">
                                <li><a href="/">INICIO</a></li>
                                <li><a href="/nosotros">NOSOTROS</a></li>
                                <li><a href="/especialidades-servicios-dentales">ESPECIALIDADES</a></li>
                                <li><a href="/testimonios">TESTIMONIOS</a></li>
                                <li><a href="/contacto">CONTACTO</a></li>
                            </ul>
                            
                        </div>
                    </div>
                </nav>

                <ul class="sidenav" id="mobile-demo">
                    <li><a href="/">INICIO</a></li>
                    <li><a href="/nosotros">NOSOTROS</a></li>
                    <li><a href="/especialidades-servicios-dentales">ESPECIALIDADES</a></li>
                    <li><a href="/testimonios">TESTIMONIOS</a></li>
                    <li><a href="/contacto">CONTACTO</a></li>
                </ul>

            </Fragment>
        ]
    }
}

export default Header;
