import React, {Fragment, useEffect}  from 'react';
import M from 'materialize-css';

import img1 from '../../Images/Escritorio/Servicios/Blanqueamiento/1.png'
import img2 from '../../Images/Escritorio/Servicios/Blanqueamiento/2.png'
import img3 from '../../Images/Escritorio/Servicios/Blanqueamiento/3.png'

const Blanqueamiento = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    },[]);

    return(
        <Fragment>
            <div>
                <div className='cont_2 cont_6 center'>
                    <p className='titulo_2 ' style={{color:"#000", padding:"0px 10%"}}>
                        Blanqueamiento <span style={{color:"#1BC9B6"}}>dental</span> 
                    </p>
                    <div className='texto_4'>
                        <p>
                            Procedimiento para devolver el color natural de los dientes, para realizarlo se utilizan productos 
                            químicos, su propósito es eliminar el obscurecimiento, las manchas y decoloraciones internas y 
                            externas. El blanqueamiento dental es una excelente opción para las personas que desean lucir una 
                            sonrisa natural, lo realizamos de una a dos sesiones, sin sensibilidad y desgaste en sus dientes 
                            naturales.
                        </p>
                    </div>
                </div>
                <div className='cont_2 '>
                    <p className='center titulo_6'>
                        CASOS CLINICOS
                    </p>
                </div>
                <div className='flex_padre cont_9' style={{ flexWrap:"wrap"}}>
                    <div className='box_6'>
                        <img src={img1} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Blanqueamiento interno
                            </h1>
                            <p>
                                Paciente masculino de 38 años de edad con diagnóstico 
                                de obscurecimiento dental por golpe en diente frontal, 
                                se realizó tratamiento de endodoncia y blanqueamiento 
                                interno en una sesión.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img2} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Blanqueamiento interno
                            </h1>
                            <p>
                                Paciente femenino de 52 años de edad con diagnóstico de 
                                obscurecimiento dental por golpe en diente frontal, se 
                                realizó tratamiento de endodoncia y blanqueamiento interno 
                                en tres sesiones.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img3} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Blanqueamiento externo
                            </h1>
                            <p>
                                Paciente femenino de 42 años de edad con diagnóstico de 
                                inconformidad del color de sus dientes naturales frontales. 
                                El tratamiento fue blanqueamiento externo en dos sesiones.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Blanqueamiento;