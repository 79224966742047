import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

import './index.css';

// *** componentes ***  //
import NavBar from './Components/Header';
import Footer from './Components/Footer';
//import Redes from './Components/Redes';

// *** vistas ***  //
import Index from './Views/Index';
import Nosotros from './Views/Nosotros';
import Servicios from './Views/Servicios';
import Contacto from './Views/Contacto';
import Testimonios from './Views/Testimonios';
import AvisoPrivacidad from './Views/AvisoPrivacidad';

// Servicios
import Cirugia from './Views/Servicios/Cirugia';
import Blanqueamiento from './Views/Servicios/Blanqueamiento';
import Edodoncia from './Views/Servicios/Edodoncia';
import Infeccion from './Views/Servicios/Infeccion';
import Traumatismo from './Views/Servicios/Traumatismo';
import Protesis from './Views/Servicios/Protesis';

import Error from './Views/Error';
import Agradecimiento from './Views/Agradecimiento';

// *** administrador *** //
import login from './Views/administrador/Login';
//usuarios
import usuarios from './Views/administrador/Usuarios/Usuarios';
import nuevoUsuario from './Views/administrador/Usuarios/NuevoUsuario';
import editarUsuario from './Views/administrador/Usuarios/EditarUsuario';
// contacto
import contactos from './Views/administrador/Contacto/Contactos';
import contacto from './Views/administrador/Contacto/Contacto';


const App = () =>(
    <Provider store={store}>
        <BrowserRouter>
            <Fragment>
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <NavBar />
                )}
                <Switch>
                    {/* vistas */}
                    <Route exact path = "/" component = {Index} />
                    <Route exact path = "/nosotros" component = {Nosotros} />
                    <Route exact path = "/especialidades-servicios-dentales" component = {Servicios} />
                    <Route exact path = "/contacto" component = {Contacto} />

                    <Route exact path = "/testimonios" component = {Testimonios} />
                    <Route exact path = "/aviso-de-privacidad" component = {AvisoPrivacidad} />

                    <Route exact path = "/especialidades/cirugia-bucal-endodontica-periodontal-y-maxilofacial" component = {Cirugia} />
                    <Route exact path = "/especialidades/blanqueamiento-dental" component = {Blanqueamiento} />
                    <Route exact path = "/especialidades/endodoncia-con-microscopio-dental" component = {Edodoncia} />
                    <Route exact path = "/especialidades/dolor-infeccion-dental-y-enfermedad-de-encias" component = {Infeccion} />
                    <Route exact path = "/especialidades/traumatismos-dentales" component = {Traumatismo} />
                    <Route exact path = "/especialidades/protesis-e-implantes-dentales" component = {Protesis} />
                    <Route exact path = "/agradecimiento" component = {Agradecimiento} />

                    {/* fin - vistas */}
                    {/* administrador */}
                    <   Route  path = "/admin/login" component = {login} />
                            {/* usuario */}
                            <Route  path = "/admin/usuarios" component = {usuarios} />
                            <Route  path = "/admin/nuevo-usuario" component = {nuevoUsuario} />
                            <Route  path = "/admin/editar-usuario/:id" component = {editarUsuario} />
                            {/* fin - usuario */}
                            {/* contactos */}
                            <Route  path = "/admin/contactos" component = {contactos} />
                            <Route  path = "/admin/contacto/:id" component = {contacto} />
                            {/* fin - contactos */}
                        {/* fin - administrador */}
                    <Route exact path = "*" component = {Error} />
                </Switch>
                
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <Footer />
                )}
            </Fragment>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
