import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';


class Contacto extends Component {
    
    constructor(props){
        super(props)
        this.state = {}
    }
    
    editar = e => {
        const { history } = this.props;
        e.preventDefault();

        //extraer valores del state
        var editar = {...this.props.contacto_individual};

        editar.estado = true;

        //extraer firestor de props
        const { firestore } = this.props;

        //actualizacion en la base de datos
        firebaseHelper.atender(this.props.contacto_individual.id, 'contacto', firestore, editar, '/admin/contactos', history);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render(){
        if (!this.props.contacto_individual) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="INFORMACIÓN CONTACTO" />
                    <div id="font-text" className="container" style={{marginTop:'0px'}}>
                        <p className='titulo_9'>{this.props.contacto_individual.nombre}</p>
                        <p className='texto_4'>Correo: {this.props.contacto_individual.correo}</p>
                        <p className='texto_4'>Telefono: {this.props.contacto_individual.telefono}</p>
                        <p className='texto_4'>Mensaje: {this.props.contacto_individual.mensaje}</p>
                        <button onClick={this.editar} id="font-button" class="btn" style={{padding:'0 50px 0 50px', background:'#1bc9b6', marginTop:"50px"}}>Atender</button>
                    </div>
                </Fragment>
            );
        }
    }
}

Contacto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'contacto',
            storeAs: 'contacto_individual',
            doc: props.match.params.id
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        contacto_individual: ordered.contacto_individual && ordered.contacto_individual[0]
    }))
)(Contacto)