import React, {Fragment, useEffect}  from 'react';
import M from 'materialize-css';

import img1 from '../../Images/Escritorio/Servicios/cirugia/1.jpg'
import img2 from '../../Images/Escritorio/Servicios/cirugia/2.png'
import img3 from '../../Images/Escritorio/Servicios/cirugia/3.jpg'

const Cirugia = () => {
    
    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    },[]);

    return(
        <Fragment>
            <div>
                <div className='cont_2 cont_6 center'>
                    <p className='titulo_2 cont_10' style={{color:"#000", padding:"0px 10%"}}>
                        Cirugía bucal, endodóntica,  <span style={{color:"#1BC9B6"}}>periodontal y maxilofacial</span> 
                    </p>
                    <div className='texto_4'>
                        <p>
                            Especialidades que atienden intervenciones quirúrgicas bucales, como la extracción de dientes 
                            dañados, cirugía de muelas del juicio, apicectomía, autotrasplante, reimplante, colocación de 
                            injertos óseos, regeneración de tejidos, enfermedades de los maxilares en cara, cabeza y cuello, 
                            involucra patologías de la articulación temporomandibular, quistes de maxilares, infecciones 
                            cervicofaciales y alteraciones craneofaciales.
                        </p>
                    </div>
                </div>
                <div className='cont_2 '>
                    <p className='center titulo_6'>
                        CASOS CLINICOS
                    </p>
                </div>
                <div className='flex_padre cont_9' style={{ flexWrap:"wrap"}}>
                    <div className='box_6'>
                        <img src={img1} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Cirugía bucal - muela del juicio
                            </h1>
                            <p>
                                Paciente femenino de 20 años que le causa dolor 
                                el tercer molar, se realiza su retiro y sutura 
                                para una mejor cicatrización, el procedimiento 
                                se hizo con microscopio dental para una mejor 
                                precisión y menor invasión de la cirugía. 
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img2} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Cirugía maxilofacial - Bichectomía
                            </h1>
                            <p>
                                Paciente femenino de 24 años de edad que se 
                                le realizó la eliminación de grasa de sus 
                                cachetes, mejor conocida como bichectomía, 
                                el propósito fue para mejorar su apariencia 
                                facial, 6 meses después se observa el resultado 
                                deseado, mejoría en su cara y sonrisa.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img3} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Cirugía endodóntica - Apicectomía 
                            </h1>
                            <p>
                                Paciente femenino de 13 años de edad. Presenta 
                                un instrumento fuera de su diente (en el hueso), 
                                se le realizo cirugía endodóntica para quitar 
                                dicho instrumento, en la imagen se observa cuando 
                                se quita el pedazo de instrumento, si no se hubiera 
                                quitado, podría tener dolor intenso, infección y 
                                hasta perdida dental. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Cirugia