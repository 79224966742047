import React, {Fragment, useEffect}  from 'react';
import M from 'materialize-css';

import img1 from '../../Images/Escritorio/Servicios/Edodoncia/1.jpg'
import img2 from '../../Images/Escritorio/Servicios/Edodoncia/2.jpg'
import img3 from '../../Images/Escritorio/Servicios/Edodoncia/3.jpg'

const Edodoncia = () => {
    
    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    },[]);
    
    return(
        <Fragment>
            <div>
                <div className='cont_2 cont_6 center'>
                    <p className='titulo_2' style={{color:"#000", padding:"0px 10%"}}>
                        Endodoncia con <br/>
                        <span style={{color:"#1BC9B6"}}>
                            Microscopio Dental
                        </span>
                    </p>
                    <div className='texto_4'>
                        <p>
                            La Endodoncia es un procedimiento que se realiza para conservar al diente natural en la boca, 
                            consiste en quitarle el nervio del diente. Se hace de una a dos citas y para que sea un 
                            tratamiento seguro debe efectuarse por un Especialista en Endodoncia, Certificado que emplee 
                            Microscopio Dental, porque aumenta la precisión y disminuye la invasión a nuestros dientes.
                        </p>
                    </div>
                </div>
                <div className='cont_2 '>
                    <p className='center titulo_6'>
                        CASOS CLINICOS
                    </p>
                </div>
                <div className='flex_padre cont_9' style={{ flexWrap:"wrap"}}>
                    <div className='box_6'>
                        <img src={img1} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 15%"}}>
                                Retiro de instrumentos y retratamiento de Endodoncia con microscopio dental
                            </h1>
                            <p>
                                Paciente femenino de 60 años de edad, con dolor intenso, en 
                                el tratamiento previo que le realizaron se observa un sellado 
                                incompleto y dos instrumentos fracturados dentro del diente, 
                                mismos que se retiraron y se repitió la endodoncia, en la imagen 
                                final se aprecia un sellado adecuado y sin instrumentos dentro 
                                del diente. 
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img2} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 15%"}}>
                                Retratamiento de Endodoncia con microscopio dental
                            </h1>
                            <p>
                                Paciente femenino de 35 años de edad, con dolor, el diagnóstico 
                                fue tratamiento previo de endodoncia, se aprecia corta y carente 
                                de limpieza, se realizó la repetición del tratamiento, en la 
                                imagen final se observa una correcta limpieza, conformación y 
                                sellado en el diente, la paciente está ya sin dolor. 
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img3} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1 style={{padding:"0px 15%"}}>
                                Endodoncia con microscopio dental
                            </h1>
                            <p>
                                Paciente femenino de 45 años de edad que presentó el diagnóstico 
                                de pulpitis (nervio inflamado) en segundo molar superior. El 
                                caso fue concluido respetando la anatomía interna del diente, 
                                a pesar de la dificultad técnica fue tratado de manera eficiente.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Edodoncia;