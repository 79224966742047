import React, {Fragment, useEffect}  from 'react';
import M from 'materialize-css';

import img1 from '../../Images/Escritorio/Servicios/Infeccion/1.png'
import img2 from '../../Images/Escritorio/Servicios/Infeccion/2.png'
import img3 from '../../Images/Escritorio/Servicios/Infeccion/3.png'

const Infeccion = () => {
    
    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);
    },[]);

    return(
        <Fragment>
            <div>
                <div className='cont_2 cont_6 center'>
                    <p className='titulo_2 cont_10' style={{color:"#000", padding:"0px 10%"}}>
                        Dolor, infección dental y <span style={{color:"#1BC9B6"}}> enfermedad de encías</span>
                    </p>
                    <div className='texto_4'>
                        <p>
                            Son padecimientos bucales que pueden ser leves como caries próxima al nervio que causa dolor 
                            dental, hasta graves como el absceso dental que provoca inflamación, asimetría facial, fiebre e 
                            incluso  la hospitalización de una persona. 
                        </p>
                        <p>
                            La enfermedad de las encías es una situación clínica que 
                            inicia con inflamación, sangrado gingival, acumulación de sarro y que puede llegar a causar la 
                            pérdida total de los dientes.

                        </p>
                    </div>
                </div>
                <div className='cont_2 '>
                    <p className='center titulo_6'>
                        CASOS CLINICOS
                    </p>
                </div>
                <div className='flex_padre cont_9' style={{flexWrap:"wrap"}}>
                    <div className='box_6'>
                        <img src={img1} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Absceso dental en piel por infección en dos dientes
                            </h1>
                            <p>
                                Paciente masculino de 45 años de edad con diagnóstico 
                                de absceso dental con salida de pus en piel. El 
                                tratamiento realizado fue drenaje, lavado quirúrgico 
                                y endodoncia en dos dientes involucrados. 1 año después 
                                se observa usencia de absceso, sin dolor e inflamación.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img2} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Drenaje de absceso dentoalveolar
                            </h1>
                            <p>
                                Paciente masculino de 25 años de edad con diagnóstico 
                                de absceso dentoalveolar agudo en fase submucosa. El 
                                tratamiento fue drenaje a partir de un corte en su 
                                encía para liberar la presión y suprimir el dolor, se 
                                le realizó endodoncia después de la fase aguda.
                            </p>
                        </div>
                    </div>
                    <div className='box_6'>
                        <img src={img3} alt="" className='img_servicio materialboxed'/>
                        <div>
                            <h1>
                                Limpieza con ultrasonido y microscopio dental
                            </h1>
                            <p>
                                Paciente masculino de 35 años que presenta acumulación 
                                de sarro, manchas de tipo crónico, su problema fue 
                                resuelto con la limpieza dental realizada con ultrasonido 
                                y microscopio dental, estos equipos nos dan una mayor 
                                precisión para dejar los dientes limpios de los depósitos 
                                de sarro y manchas.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Infeccion;