import React, {Fragment}  from 'react';
import Mapa from '../Components/Mapa';

import Formulario from '../Components/Formulario';

import icon1 from '../Images/Iconos/Contactos/1.svg'
import icon2 from '../Images/Iconos/Contactos/2.svg'
import icon3 from '../Images/Iconos/Contactos/3.svg'
import icon4 from '../Images/Iconos/Contactos/4.svg'
import icon5 from '../Images/Iconos/Contactos/5.svg'
import icon6 from '../Images/Iconos/Contactos/6.svg'
import icon7 from '../Images/Iconos/Contactos/7.svg'
import icon8 from '../Images/Iconos/Contactos/8.svg'


const Contacto = () => {
    return (       
        <Fragment>
            <div>
                <div className='img_contactos'>
                    <p className='titulo_1'>
                        Contacto
                    </p>
                </div>

                <div className='center cont_1'>
                    <p className='titulo_2' style={{marginBottom:"0px"}}>
                        ¿Necesitas una cita?
                    </p>
                    <p className='texto_1' style={{marginTop:"0px", padding:"0px 10%"}}>
                        Contáctanos, estaremos encantados de poder atenderte 
                    </p>
                    <div className='flex_padre'>
                        <div className='flex_padre' style={{flexDirection:"column"}}>
                            <div className='flex_contactos_1'>
                                <img src={icon3} alt="" style={{width:"19px", height:"19px", margin:"0px 15px"}}/>
                                <p className='texto_2'>
                                    33-1894-1320 <br/>
                                    33-2335-5830
                                </p>
                            </div>
                            <div className='flex_contactos_1'> 
                                <img src={icon6} alt="" style={{width:"22px", height:"16px", margin:"0px 15px"}}/>
                                <p className='texto_2'>
                                    endobr1@hotmail.com 
                                </p>
                            </div>
                            <div className='flex_contactos_1'>
                                <img src={icon8} alt="" style={{width:"21px", height:"21px", margin:"0px 15px"}}/>
                                <p className='texto_2'>
                                    Lunes a viernes <br/>
                                    de 8:30 am. - 9 pm.
                                </p>
                                <p className='texto_2 sab'>
                                    Sábados<br/>
                                    de 8:30 am. - 3 pm.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='cont_1' style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
                    <div>
                        <a target='blanck' href="https://api.whatsapp.com/send?phone=3318941320&text=Hola,%20muy%20buenas%20tardes,%20me%20gustar%C3%ADa%20recibir%20mas%20informaci%C3%B3n%20o%20agendar%20una%20cita">
                            <img src={icon4} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                        </a>
                    </div>
                    <div>
                        <a target='blanck' href="https://www.facebook.com/drNorbertoJBroon">
                            <img src={icon2} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                        </a>
                    </div>
                    <div>
                        <a target='blanck' href="https://www.instagram.com/drnorbertobroon">
                            <img src={icon7} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                        </a>
                    </div>
                    <div>
                        <a target='blanck' href="https://www.doctoralia.com.mx/norberto-broon/dentista-odontologo/zapopan">
                            <img src={icon5} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                        </a>
                    </div>
                </div>

                <div className='center'>
                    <p className='titulo_2' style={{color:"#000",marginBottom:"0px"}}>
                        Ubica nuestras instalaciones
                    </p>
                    <p className='titulo_2' style={{color:"#1BC9B6", marginTop:"0px", marginBottom:"0px"}}>
                        ¡Te esperamos!
                    </p>
                </div>
                <div className='flex_contactos_1' style={{marginTop:"20px"}}>
                    <img src={icon1} alt="" style={{width:"18px", height:"25px", margin:"0px 25px"}}/>
                    <p className='texto_2'>
                        Av. Cuauhtémoc 1192, <br/>
                        Cd del Sol, 45050 Zapopan, Jalisco
                    </p>
                </div>
            </div>
            <div className='cont_4'>
                <Mapa/>
                
            </div>
            <div className='center cont_2'>
                    <p className='titulo_2'>
                        <span style={{color:"#000"}}>Recibe la mejor atención</span> <br/>
                        100% personalizada
                    </p>
                </div>
            <Formulario />

        </Fragment>
    )
}

export default Contacto;