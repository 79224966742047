import React, {Fragment}  from 'react';

import Formulario from '../Components/Formulario';

import testimonial1 from '../Images/Escritorio/Testimoniales/1.png';
import testimonial2 from '../Images/Escritorio/Testimoniales/2.png';
import testimonial3 from '../Images/Escritorio/Testimoniales/3.png';
import testimonial4 from '../Images/Escritorio/Testimoniales/4.png';
import testimonial5 from '../Images/Escritorio/Testimoniales/5.png';
import testimonial6 from '../Images/Escritorio/Testimoniales/6.png';

const Testimonios = () => {

    return(
        <Fragment>
            <div>
                <div className='center cont_2'>
                    <div>
                        <p className='titulo_6' style={{color:"#1BC9B6", marginBottom:"0px"}}>
                            TESTIMONIOS
                        </p>
                        <p className='titulo_7 cont_8' style={{marginTop:"0px"}}>
                            Lo que dicen nuestros pacientes
                        </p>    
                    </div>
                </div>

                <div className='flex_padre' style={{alignItems:"stretch", flexWrap:"wrap"}}>
                    <div className='box_7'>
                            <div className='flex_padre'>
                                <div style={{width:"150px", height:"150px", overflow:"hidden", position:"relative", top:"-65px", borderRadius:"50%"}}>
                                    <img src={testimonial1} alt="" style={{width:"100%"}}/>
                                </div>
                            </div>
                            <div className='caja_2'>
                                <div style={{marginBottom:"10px"}}>
                                    <p className='center titulo_8' style={{margin:"0px"}}>
                                        Herminia
                                    </p>
                                    <p className='center texto_4' style={{color:"#858585", marginTop:"0px"}}>
                                        Primera visita Odontología
                                    </p>
                                </div>
                                <div className='scrol-testimonial' style={{height:"150px", overflow:"auto"}}>
                                    <p className='texto_5' style={{color:"#858585"}}>
                                        “Lo recomiendo al 1000. Yo le tengo fobia a los dentistas por las malas 
                                        experiencias vívidas y con él fue todo lo contrario, es un gran profesionista 
                                        y sobre todo un gran ser humano muchas gracias dr. por su atención tiene mi 
                                        confianza al 1000.”.
                                    </p>
                                </div>
                            </div>
                    </div>
                    <div className='box_7'>
                        <div className='flex_padre'>
                            <div style={{width:"150px", height:"150px", overflow:"hidden", position:"relative", top:"-65px", borderRadius:"50%"}}>
                                <img src={testimonial2} alt="" style={{width:"100%"}}/>
                            </div>
                        </div>
                        <div className='caja_2'>
                            <div style={{marginBottom:"10px"}}>
                                <p className='center titulo_8' style={{margin:"0px"}}>
                                    Gabriela Figueroa
                                </p>
                                <p className='center texto_4' style={{color:"#858585", marginTop:"0px"}}>
                                    Visitas sucesivas Odontología
                                </p>
                            </div>
                            <div className='scrol-testimonial' style={{height:"150px", overflow:"auto"}}>
                                <p className='texto_5' style={{color:"#858585"}}>
                                    “Es un excelente doctor, súper recomendado, nada doloroso, las instalaciones 
                                    están muy limpias y sobre todo son muy puntuales. Es el mejor dentista con 
                                    el que he ido, lo recomiendo.”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='box_7'>
                        <div className='flex_padre'>
                            <div style={{width:"150px", height:"150px", overflow:"hidden", position:"relative", top:"-65px", borderRadius:"50%"}}>
                                <img src={testimonial3} alt="" style={{width:"100%"}}/>
                            </div>
                        </div>
                        <div className='caja_2'>
                            <div style={{marginBottom:"10px"}}>
                                <p className='center titulo_8' style={{margin:"0px"}}>
                                    Karime
                                </p>
                                <p className='center texto_4' style={{color:"#858585", marginTop:"0px"}}>
                                    Autotrasplante y reimplante dental
                                </p>
                            </div>
                            <div className='scrol-testimonial' style={{height:"150px", overflow:"auto"}}>
                                <p className='texto_5' style={{color:"#858585"}}>
                                    “Excelente trato y servicio hizo muy bien su trabajo, tiene sus instalaciones muy 
                                    limpias y su asistente es una excelente persona muy amable te da mucha seguridad 
                                    y tranquilidad.”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='box_7'>
                        <div className='flex_padre'>
                            <div style={{width:"150px", height:"150px", overflow:"hidden", position:"relative", top:"-65px", borderRadius:"50%"}}>
                                <img src={testimonial4} alt="" style={{width:"100%"}}/>
                            </div>
                        </div>
                        <div className='caja_2'>
                            <div style={{marginBottom:"10px"}}>
                                <p className='center titulo_8' style={{margin:"0px"}}>
                                    Edda Lys
                                </p>
                                <p className='center texto_4' style={{color:"#858585", marginTop:"0px"}}>
                                    Primera visita Odontología
                                </p>
                            </div>
                            <div className='scrol-testimonial' style={{height:"150px", overflow:"auto"}}>
                                <p className='texto_5' style={{color:"#858585"}}>
                                    “Excelente dentista, sufro de ataques de ansiedad cada vez que visito a un 
                                    dentista, pero el me trató increíble, me tuvo paciencia, me explicó todo a 
                                    detalle y por fin me dan ganas de regresar con un dentista.”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='box_7'>
                        <div className='flex_padre'>
                            <div style={{width:"150px", height:"150px", overflow:"hidden", position:"relative", top:"-65px", borderRadius:"50%"}}>
                                <img src={testimonial5} alt="" style={{width:"100%"}}/>
                            </div>
                        </div>
                        <div className='caja_2'>
                            <div style={{marginBottom:"10px"}}>
                                <p className='center titulo_8' style={{margin:"0px"}}>
                                    Claudia García
                                </p>
                                <p className='center texto_4' style={{color:"#858585", marginTop:"0px"}}>
                                    Endodoncia con microscopio
                                </p>
                            </div>
                            <div className='scrol-testimonial' style={{height:"150px", overflow:"auto"}}>
                                <p className='texto_5' style={{color:"#858585"}}>
                                    “Lo super recomiendo, cuenta con buena aparatología lo cual 
                                    hace más preciso y eficaz el tratamiento a realizar, muy dedicado, 
                                    paciente y explica todo el proceso a realizar, estoy agradecida de 
                                    que caí en sus manos y expertis.”.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='box_7'>
                        <div className='flex_padre'>
                            <div style={{width:"150px", height:"150px", overflow:"hidden", position:"relative", top:"-65px", borderRadius:"50%"}}>
                                <img src={testimonial6} alt="" style={{width:"100%"}}/>
                            </div>
                        </div>
                        <div className='caja_2'>
                            <div style={{marginBottom:"10px"}}>
                                <p className='center titulo_8' style={{margin:"0px"}}>
                                    Oscar Rodríguez
                                </p>
                                <p className='center texto_4' style={{color:"#858585", marginTop:"0px"}}>
                                    Endodoncia con microscopio
                                </p>
                            </div>
                            <div className='scrol-testimonial' style={{height:"150px", overflow:"auto"}}>
                                <p className='texto_5' style={{color:"#858585"}}>
                                    “¡Excelente todo! Me gusto mucho la manera en que me explicó el proceso, 
                                    todo muy detalladamente y la manera en que hizo el trabajo muy eficaz, 
                                    sobre todo muy amable el Dr.”.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex_padre' style={{flexWrap:"wrap", alignItems:"stretch"}}>
                    <div className='box_8'>
                        <iframe height="315" 
                        src="https://www.youtube.com/embed/fByJBtjtBC0" 
                        title="YouTube video player" frameborder="0" 
                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                        <div className='texto_6'>
                            <p>
                                <span style={{color:"#7A3C8F"}}>Endodoncia, cirugía y carilla estética.</span>
                            </p>
                            <p>
                                Paciente femenino que presentaba dolor e infección en un diente frontal. 
                                Le realizamos un tratamiento de endodoncia, una cirugía apical y una carilla 
                                estética, todo con la finalidad de que no perdiera su diente natural frontal, 
                                esta muy contenta con los resultados obtenidos y nos recomienda ampliamente. 
                            </p>
                        </div>
                    </div>
                    <div className='box_8'>
                        <iframe height="315" 
                        src="https://www.youtube.com/embed/d8iUaUfENHQ" 
                        title="YouTube video player" frameborder="0" 
                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                        <div className='texto_6'>
                            <p>
                                <span style={{color:"#7A3C8F"}}>Endodoncia con microscopio.</span> 
                            </p>
                            <p>
                                Paciente femenino que acudió para realizar un tratamiento de 
                                endodoncia, mismo que se realizó en dos sesiones debido a la 
                                situación clínica que presentaba. En todo el procedimiento de 
                                endodoncia no presentó dolor, incomodidad, fue resuelto 
                                favorablemente, sus dientes fueron restaurados con resinas.
                            </p>
                        </div>
                    </div>
                    <div className='box_8'>
                        <iframe height="315" 
                        src="https://www.youtube.com/embed/6PxgozvxUHk" 
                        title="YouTube video player" frameborder="0" 
                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                        <div className='texto_6'>
                            <p>
                                <span style={{color:"#7A3C8F"}}>Rehabilitación protésica integral.</span>
                            </p>
                            <p>
                                Paciente femenino que cuando acudió con nosotros refirió que 
                                iba perder 4 dientes, después de hacer un diagnóstico 
                                preciso no perdió ningún diente y le realizamos una 
                                rehabilitación dental integral con las especialidades de 
                                endodoncia, periodoncia y prótesis fija estética, el resultado 
                                obtenido para ella fue muy satisfactorio, ya que se le devolvió 
                                la funcionalidad y estética a su boca sin someterla a 
                                extracciones dentales, se encuentra muy contenta.
                            </p>
                        </div>
                    </div>
                    <div className='box_8'>
                        <iframe height="315" 
                        src="https://www.youtube.com/embed/J-_JEg6LB_A" 
                        title="YouTube video player" frameborder="0" 
                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                        <div className='texto_6'>
                            <p>
                                <span style={{color:"#7A3C8F"}}>Endodoncia y cirugía con microscopio.</span>
                            </p>
                            <p>
                                Paciente femenino que se le realizaron procedimientos de endodoncia, 
                                debido a problemas que presentaba y además cirugía para corregir secuelas 
                                de los procedimientos antes de la endodoncia, va en muy buena fase de 
                                recuperación. Actualmente ya no presenta incomodidad, dolor, inflamación 
                                e infección, sigue en fase de revisión y control clínico-radiográfico.
                            </p>
                        </div>
                    </div>
                    <div className='box_8'>
                        <iframe height="315" 
                        src="https://www.youtube.com/embed/c9yB49FpFWc" 
                        title="YouTube video player" frameborder="0" 
                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                        <div className='texto_6'>
                            <p>
                                <span style={{color:"#7A3C8F"}}>Blanqueamiento dental.</span>
                            </p>
                            <p>
                                Paciente femenino quien presentaba inconformidad por el color de sus 
                                dientes, realizamos un blanqueamiento dental en dos sesiones, previa 
                                limpieza dental, eliminación de manchas y pulido dental, al termino del 
                                blanqueamiento se muestra satisfecha con los resultados obtenidos y nos 
                                recomienda ampliamente.
                            </p>
                        </div>
                    </div>
                    <div className='box_8'>
                        <iframe height="315" 
                        src="https://www.youtube.com/embed/ZfdGFYNk3aA" 
                        title="YouTube video player" frameborder="0" 
                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                        <div className='texto_6'>
                            <p>
                                <span style={{color:"#7A3C8F"}}>
                                    Endodoncia con microscopio y Rehabilitación protésica en paciente ansiosa.
                                </span>
                            </p>
                            <p>
                                Paciente femenino con ansiedad a la consulta dental y a los procedimientos 
                                dental. Acudió a consulta por presentar fractura dental y dolor, le realizamos 
                                endodoncia y corona cerámica, previo manejo adecuado de su ansiedad, en la 
                                actualidad acude a sus citas de revisión y ha logrado superar la ansiedad 
                                que tuvo al consultorio dental.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='center cont_2'>
                    <p className='titulo_2'>
                        <span style={{color:"#000"}}>Recibe la mejor atención</span> <br/>
                        100% personalizada
                    </p>
                </div>

                <Formulario />
            </div>
        </Fragment>
    )
}

export default Testimonios;