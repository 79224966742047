import React, {Component} from 'react';

import img1 from '../Images/Header/logo1.png';

import icon1 from '../Images/Footer/1.svg'
import icon2 from '../Images/Footer/2.svg'
import icon3 from '../Images/Footer/3.svg'
import icon4 from '../Images/Footer/4.svg'
import icon5 from '../Images/Footer/5.svg'
import icon6 from '../Images/Footer/6.svg'
import icon7 from '../Images/Footer/7.svg'

import icon8 from '../Images/Footer/1.png'
import icon9 from '../Images/Footer/2.png'
import icon10 from '../Images/Footer/3.png'


class Footer extends Component {

    render (){
        return [
            <footer class="page-footer" style={{background:"#F4F4F4", color:"#6C6C6C"}}>
                <div>
                    <div class="row footer_1">
                        <div className='col s12 l5' >
                            <div className='flex_footer'>
                                <img src={img1} alt="" className='img_footer'/>
                                <p className='texto_footer'>
                                    Somos un grupo de cirujanos dentistas 
                                    profesionales con amplia experiencia en 
                                    diversas especialidades de la odontología.
                                </p>
                            </div>
                        </div>
                        <div className='col s12 l3' style={{padding:"0px"}}>
                            <div className='flex_footer_2'>
                                <p className='titulo_footer'>
                                    Contacto
                                </p>
                            </div>
                            <div>
                                <div className='flex_footer_2_1'>
                                    <img src={icon1} alt="" className='icon_footer_2'  style={{width:"14px", height:"21px"}}/>
                                    <p className='texto_footer' style={{margin:"8px 0px"}}>
                                        Av. Cuauhtémoc 1192, <br/>
                                        Cd del Sol, 45050 Zapopan, Jal.
                                    </p>
                                </div>
                                <div className='flex_footer_2_1'>
                                    <img src={icon2} alt="" className='icon_footer_2' style={{width:"20px", height:"14px"}}/>
                                    <p className='texto_footer' style={{margin:"8px 0px"}}>
                                        endobr1@hotmail.com 
                                    </p>
                                </div>
                                <div className='flex_footer_2_1'>
                                    <img src={icon3} alt="" className='icon_footer_2' style={{width:"20px", height:"23px"}}/>
                                    <p className='texto_footer' style={{margin:"8px 0px"}}>
                                        33 1894 1320 <br/>
                                        33 2335 5830
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 l2 footer_2' >
                            <div className='flex_footer_2'>
                                <div>
                                    <h5 class="titulo_footer">
                                        Inicio
                                    </h5>
                                </div>
                                <div>
                                    <ul className='texto_footer'>
                                        <li style={{margin:"12px 0px"}}>
                                            <a class="" href="/nosotros">
                                                Nosotros
                                            </a>
                                        </li>
                                        <li style={{margin:"12px 0px"}}>
                                            <a class="" href="/especialidades-servicios-dentales">
                                                Especialidades
                                            </a>
                                        </li>
                                        <li style={{margin:"12px 0px"}}>
                                            <a class="" href="/testimonios">
                                                Testimonios
                                            </a>
                                        </li>
                                        <li style={{margin:"12px 0px"}}>
                                            <a class="" href="/contacto">
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 l2' style={{padding:"0px"}}>
                            <div className='flex_footer_2'>
                                <p className='titulo_footer'>
                                    Síguenos
                                </p>
                            </div>

                            <div className='cont_1 cont_footer' style={{display:"flex", justifyContent:"space-evenly", alignItems:"stretch"}}>
                                <div>
                                    <a target='blanck' href="https://api.whatsapp.com/send?phone=3318941320&text=Hola,%20muy%20buenas%20tardes,%20me%20gustar%C3%ADa%20recibir%20mas%20informaci%C3%B3n%20o%20agendar%20una%20cita">
                                        <img src={icon5} alt="" className='icon_footer_1'/>
                                    </a>
                                </div>
                                <div>
                                    <a target='blanck' href="https://www.facebook.com/drNorbertoJBroon">
                                        <img src={icon4} alt="" className='icon_footer_1'/>
                                    </a>
                                </div>
                                <div>
                                    <a target='blanck' href="https://www.instagram.com/drnorbertobroon">
                                        <img src={icon6} alt="" className='icon_footer_1'/>
                                    </a>
                                </div>
                                <div>
                                    <a target='blanck' href="https://www.doctoralia.com.mx/norberto-broon/dentista-odontologo/zapopan">
                                        <img src={icon7} alt="" className='icon_footer_1'/>
                                    </a>
                                </div>
                            </div>


                            <div className='cont_1 cont_footer' style={{display:"flex", justifyContent:"space-evenly", alignItems:"stretch"}}>
                                <div>
                                    <img src={icon8} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                                </div>
                                <div>
                                    <img src={icon9} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                                </div>
                                <div>
                                    <img src={icon10} alt="" style={{width:"39px", height:"39px", margin:"0px 5px"}}/>
                                </div>
                            </div>
                            <a href="/aviso-de-privacidad" className='texto_footer_2 center'>
                                <p >
                                    Aviso de privacidad
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="footer-copyright copy" style={{background:"#7A3C8F"}}>
                    <div class="container center" style={{fontSize:"14px", color:"white"}}>
                        Copyright ©2022 Odontologos Especialistas Militares.
                    </div>
                </div>
            </footer>
        ]
    }
}

export default Footer;