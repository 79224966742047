import React, {Fragment}  from 'react';

import dr1 from '../Images/Escritorio/Index/dr1.jpeg';
import dr2 from '../Images/Escritorio/Index/dr2.jpeg';
import dr3 from '../Images/Escritorio/Index/dr3.jpeg';

import icon1 from '../Images/Iconos/Index/1.svg';
import icon2 from '../Images/Iconos/Index/2.svg';
import icon3 from '../Images/Iconos/Index/3.svg';

import Formulario from '../Components/Formulario';
import Mapa from '../Components/Mapa';

const Index = () =>{
    return(
        <Fragment>
            <div>
                <div className='img_index_1'/>
                <div className='center'>
                    <p className='titulo_9' style={{marginBottom:"0px"}}>
                        Profesionales altamente calificados <br/>
                        al cuidado de tu salud dental. 
                    </p>
                    <div className='texto_4 cont_6'>
                        <p>
                            Somos un grupo de cirujanos dentistas profesionales con amplia experiencia <br/>
                            en diversas especialidades de la odontología.
                        </p>
                        <p>
                            Ofrecemos un tratamiento 100% personalizado.
                        </p>
                    </div>
                    <div className='flex_padre'>
                        <a href="/contacto" className='btn_3'>
                            <p>
                                Agenda una cita
                            </p>
                        </a>
                    </div>
                </div>
                <div className='img_index_2'>
                    <p className='titulo_1 center'>
                        Conoce nuestros servicios
                    </p>
                </div>
                <div className='flex_index_1'>
                    <div className='box_9'>
                        <img src={icon2} alt="" className='icon_index_2' />
                        <p className='titulo_8'>
                            Endodoncia con Microscopio Dental
                        </p>
                        <div>
                            <p className='texto_5' style={{color:"#858585", marginTop:"0px",fontWeight:"bold"}}>
                                La Endodoncia es un procedimiento que se realiza 
                                para conservar al diente natural en la boca, consiste en...
                            </p>
                            <a href="/especialidades/endodoncia-con-microscopio-dental" className='texto_5' style={{color:"#7A3C8F", fontWeight:"bold"}}>
                                <u>
                                    Conoce más
                                </u> 
                            </a>
                        </div>
                    </div>
                    <div className='box_9'>
                        <img src={icon3} alt="" className='icon_index_1' />
                        <p className='titulo_8'>
                            Prótesis e Implantes Dentales
                        </p>
                        <div>
                            <p className='texto_5' style={{color:"#858585", marginTop:"0px",fontWeight:"bold"}}>
                                Especialidad responsable de 
                                devolver la función y estética 
                                a personas que han perdido 
                                dientes...
                            </p>
                            <a href="/especialidades/protesis-e-implantes-dentales" className='texto_5' style={{color:"#7A3C8F", fontWeight:"bold"}}>
                                <u>
                                    Conoce más
                                </u> 
                            </a>
                        </div>
                    </div>
                    <div className='box_9'>
                        <img src={icon1} alt="" className='icon_index_1' />
                        <p className='titulo_8'>
                            Dolor, infección dental y enfermedad de encías
                        </p>
                        <div>
                            <p className='texto_5' style={{color:"#858585", marginTop:"0px",fontWeight:"bold"}}>
                                Son padecimientos bucales que pueden ser 
                                leves como caries próxima al nervio que 
                                causa dolor dental, hasta graves como...
                            </p>
                            <a href="/especialidades/dolor-infeccion-dental-y-enfermedad-de-encias" className='texto_5' style={{color:"#7A3C8F", fontWeight:"bold"}}>
                                <u>
                                    Conoce más
                                </u> 
                            </a>
                        </div>
                    </div>
                </div>

                <div className='cont_2 flex_padre'>
                    <a href="/especialidades-servicios-dentales" className='btn_3'>
                        Ver todos
                    </a>
                </div>
                <div>
                    <div className='center'>
                        <p className='titulo_6' style={{color:"#1BC9B6", marginBottom:"0px"}}>
                            EQUIPO
                        </p>
                        <p className='titulo_7' style={{marginTop:"0px"}}>
                            Conoce a nuestros <br/>
                            especialistas
                        </p>
                    </div>
                    <div className='flex_padre' style={{flexWrap:"wrap", alignItems:"flex-start"}}>
                        <div className='box_10' style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-between"}}>
                            <div className=''>
                                <img src={dr1} alt="" className='img_index_3' />
                                <div>
                                    <p className='titulo_8' style={{margin:"0px"}}>
                                        Dr. Norberto Broon
                                    </p>
                                    <p className='texto_5' style={{color:"#858585", marginTop:"0px", fontWeight:"bold"}}>
                                        Cirujano Dentista Militar
                                    </p>
                                    <p className='texto_2' style={{color:"#858585", fontWeight:"bold"}}>
                                        Especialista en Endodoncia y cirugía con microscopio dental
                                    </p>
                                </div>
                            </div>
                            <div className='flex_padre boton'>
                                <a href="/nosotros#norberto" className='btn_4'>
                                    Ver más
                                </a>
                            </div>
                        </div>
                        <div className='box_10' style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-between"}}>
                            <div className=''>
                                <img src={dr2} alt="" className='img_index_3' />
                                <div>
                                    <p className='titulo_8' style={{margin:"0px"}}>
                                        Dra. Diana Figueroa
                                    </p>
                                    <p className='texto_5' style={{color:"#858585", marginTop:"0px", fontWeight:"bold"}}>
                                        Cirujano Dentista
                                    </p>
                                    <p className='texto_2' style={{color:"#858585", fontWeight:"bold"}}>
                                        Especialista en protesis y estetica dental
                                    </p>
                                </div>
                            </div>
                            <div className='flex_padre boton'>
                                <a href="/nosotros#diana" className='btn_4'>
                                    Ver más
                                </a>
                            </div>
                        </div>
                        <div className='box_10' style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-between"}}>
                            <div className=''>
                                <img src={dr3} alt="" className='img_index_3' />
                                <div>
                                    <p className='titulo_8' style={{margin:"0px"}}>
                                        Dr. Álvaro Villegas
                                    </p>
                                    <p className='texto_5' style={{color:"#858585", marginTop:"0px", fontWeight:"bold"}}>
                                        Cirujano Dentista
                                    </p>
                                    <p className='texto_2' style={{color:"#858585", fontWeight:"bold"}}>
                                        Especialista en periodoncia e implantes dentales 
                                    </p>
                                </div>
                            </div>
                            <div className='flex_padre'>
                                <a href="/nosotros#alvaro" className='btn_4'>
                                    Ver más
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='center cont_2 '>
                    <p className='titulo_9' style={{marginBottom:"0px"}}>
                        Ofrecemos un servicio de odontología <br/>
                        integral y de gran calidad.
                    </p>
                    <div className='cont_7 texto_4'>
                        <p>
                            Utilizando la más innovadora tecnología, nuestro equipo de odontólogos especialistas
                            se compromete a ofrecerte un servicio integral para tu salud dental. 
                            
                        </p>
                        <p> 
                            Conócenos y obtén la mejor atención con experiencia, conocimiento, <br/>
                            profesionalismo y excelente trato.
                        </p>
                    </div>
                </div>

                <Formulario />

                <p className='titulo_2 center' style={{color:"#000"}}>
                    Ubica nuestras instalaciones <br/>
                    <span style={{color:"#1BC9B6"}}>
                        ¡Te esperamos!
                    </span>
                </p>

                <Mapa/>
            </div>
        </Fragment>
    )
}

export default Index;